import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import { useInquiryUrl } from '../../hooks/useInquiryUrl';

const Footer: FC = () => {
  const inquiryFormUrl = useInquiryUrl();

  return (
    <Flex
      position={'relative'}
      direction={'row'}
      background={'#ffffff'}
      width={'full'}
      px={0}
      py={'55px'}
      marginTop={'100px'}
      borderTop={'1px solid #bbbbbb'}
    >
      <HStack
        justifyContent={'center'}
        alignItems={'flex-start'}
        w={'full'}
        spacing={'24px'}
        paddingX={'12px'}
      >
        <VStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          whiteSpace={'nowrap'}
        >
          <Text
            w={'full'}
            textAlign={'center'}
            fontSize={'md'}
            fontWeight={'bold'}
          >
            プロダクト
          </Text>
          <Link target="_blank" to="https://pochico.app" rel="noopener">
            <Text fontSize={'sm'} fontWeight={'bold'} color={'slategray'}>
              かんたん予約アプリ『ポチコ』
            </Text>
          </Link>
          <Link target="_blank" to="https://pochico.app/tos" rel="noopener">
            <Text fontSize={'sm'} fontWeight={'bold'} color={'slategray'}>
              利用規約
            </Text>
          </Link>
          <Link
            target="_blank"
            to="https://pochico.app/tokushouhou"
            rel="noopener"
          >
            <Text fontSize={'sm'} fontWeight={'bold'} color={'slategray'}>
              特定商取引法に基づく表示
            </Text>
          </Link>
        </VStack>

        <VStack alignItems={'flex-start'} justifyContent={'flex-start'}>
          <Text
            w={'full'}
            textAlign={'center'}
            fontSize={'md'}
            fontWeight={'bold'}
          >
            運営会社
          </Text>
          <Link target="_blank" to="https://play-tech.jp" rel="noopener">
            <Text fontSize={'sm'} fontWeight={'bold'} color={'slategray'}>
              株式会社Play Technologies
            </Text>
          </Link>
          <Link
            target="_blank"
            to="https://play-tech.jp/privacy-policy"
            rel="noopener"
          >
            <Text fontSize={'sm'} fontWeight={'bold'} color={'slategray'}>
              プライバシーポリシー
            </Text>
          </Link>
          <Link target="_blank" to={inquiryFormUrl} rel="noopener">
            <Text fontSize={'sm'} fontWeight={'bold'} color={'slategray'}>
              お問い合わせ
            </Text>
          </Link>
        </VStack>
      </HStack>
    </Flex>
  );
};

export default Footer;
