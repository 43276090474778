import FirebaseFirestore from '@google-cloud/firestore';
import {
  nullToUndefined,
  ProviderAccount,
  undefinedToNull,
} from '@pochico/shared';
import {
  collection,
  doc,
  getDoc,
  QueryDocumentSnapshot,
  updateDoc,
} from 'firebase/firestore';

import CONSTANTS from '../../commons/constants';
import { db } from '../../firebase/firebaseInit';
import {
  FromFSProviderAccount,
  ToFSProviderAccount,
} from '../../firebase/types';

export const getProviderAccountRef = (providerId: string) => {
  return doc(
    collection(db, CONSTANTS.COLLECTION.PROVIDER_ACCOUNTS),
    providerId
  ).withConverter({
    toFirestore(docData: ProviderAccount): ToFSProviderAccount {
      console.log('toFirestore', { docData });
      return {
        ...docData,

        // firestore.rulesで許可されているフィールドのみ
        reminder: undefinedToNull(docData.reminder),
        theme: undefinedToNull(docData.theme),
        bookingLimitation: undefinedToNull(docData.bookingLimitation),
        onboardingProgress: undefinedToNull(docData.onboardingProgress),
      };
    },
    fromFirestore(
      snap: QueryDocumentSnapshot<FromFSProviderAccount>
    ): ProviderAccount {
      const _data = snap.exists() ? snap.data() : undefined;
      if (!_data || !_data.id || !_data.displayName) {
        throw new Error(`providerAccount ${providerId} not found`);
      }
      return {
        ..._data,
        basicId: nullToUndefined(_data.basicId),
        premiumId: nullToUndefined(_data.premiumId),
        displayName: nullToUndefined(_data.displayName),
        pictureUrl: nullToUndefined(_data.pictureUrl),
        reminder: nullToUndefined(_data.reminder),
        theme: nullToUndefined(_data.theme),
        bookingLimitation: nullToUndefined(_data.bookingLimitation),
        spotDisplayMaxDays: nullToUndefined(_data.spotDisplayMaxDays),
        onboardingProgress: nullToUndefined(_data.onboardingProgress),
        metadata: nullToUndefined(_data.metadata),
        customText: nullToUndefined(_data.customText),
        createTime: _data.createTime!.toDate(),
        updateTime: _data.updateTime!.toDate(),
      } as ProviderAccount;
    },
  });
};

export const fetchProviderAccount = async (
  providerAccountId: string
): Promise<ProviderAccount | undefined> => {
  return getDoc(getProviderAccountRef(providerAccountId as string)).then(
    (doc) => {
      const providerAccount = doc.exists() ? doc.data() : undefined;
      if (providerAccount) {
        // console.log(`fetched ProviderAccount ${providerAccountId}`, {
        //   providerAccount,
        // });
        return providerAccount;
      }
      return;
    }
  );
};

export const updateProviderAccount = async (
  providerAccount: ProviderAccount,
  updateData: FirebaseFirestore.UpdateData<ProviderAccount>
) => {
  console.log({ updateData });
  return updateDoc(getProviderAccountRef(providerAccount.id), {
    ...updateData,
    updateTime: new Date(),
  });
};
