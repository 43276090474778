import {
  Button,
  HStack,
  Icon,
  Image,
  Spinner,
  Stack,
  StackDivider,
  Switch,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  PaymentDateCalculator,
  ProviderAccount,
  waitAtLeast,
} from '@pochico/shared';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import { MdCheckCircle } from 'react-icons/md';
import { setDocumentTitle } from '../commons/components/setDocumentTitle';
import { NeedBookingMenuSwitcher } from '../components/features/bookingMenus/list/NeedBookingMenuSwitcher';
import { Layout } from '../components/ui/Layout';
import { Link } from '../components/ui/Link';
import { Loading } from '../components/ui/Loading';
import { NavigationButton } from '../components/ui/NavigationButton';
import { useAuthState } from '../context/providerAccount';
import { fetchCustomerPortalURL } from '../dataStore/paymentRepository';
import { useRichMenu } from '../dataStore/richMenuRepository';
import { FirebaseUser } from '../firebase/firebaseInit';
import { Path } from '../routers/Path';
import { GoogleCalendarConfigurationButton } from './GoogleCalendarConfigurationButton';

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
};

const Configuration = () => {
  const { initialized, firebaseUser, providerAccount } = useAuthState();
  const navigate = useNavigate();
  setDocumentTitle('各種設定');

  if (!initialized) {
    return <Loading />;
  }
  if (!firebaseUser || !providerAccount) {
    alert('ログインしてください');
    navigate('/login');
    return <></>;
  }

  return (
    <Layout pageTitle="各種設定">
      <VStack w={'full'} alignItems={'flex-start'} spacing={'32px'}>
        <PaymentConfiguration
          firebaseUser={firebaseUser}
          providerAccount={providerAccount}
        />
        <FeatureConfiguration providerAccount={providerAccount} />
        <AccountConfiguration />
        <Version />
      </VStack>
    </Layout>
  );
};

export default Configuration;

const ConfigurationMenu: React.FC<{
  imagePath?: string;
  title: string;
  children: React.ReactNode;
}> = ({ imagePath, title, children }) => {
  return (
    <Stack
      direction={{ base: 'column-reverse', md: 'row' }}
      w={'full'}
      color={'black'}
      alignItems={{ base: 'center', md: 'flex-start' }}
      justifyContent={{ base: 'center', md: 'flex-start' }}
      px={'16px'}
      py={'32px'}
      spacing={'16px'}
    >
      {imagePath && (
        <Image src={imagePath} alt={title} width={'286px'} height={'220px'} />
      )}
      <VStack
        w={'full'}
        alignItems={'flex-start'}
        gap={'0px'}
        color={'gray.800'}
      >
        <Text fontWeight={'bold'}>{title}</Text>
        {children}
      </VStack>
    </Stack>
  );
};

const PaymentConfiguration = ({
  firebaseUser,
  providerAccount,
}: {
  firebaseUser: FirebaseUser;
  providerAccount: ProviderAccount;
}) => {
  const portalQuery = useQuery({
    queryKey: ['portal', providerAccount.id],
    queryFn: () =>
      waitAtLeast(fetchCustomerPortalURL(firebaseUser, providerAccount), 3000),
  });
  const pd = React.useMemo(
    () => new PaymentDateCalculator(providerAccount, dayjs()),
    [providerAccount]
  );
  return (
    <VStack w={'full'} alignItems={'flex-start'}>
      <Text fontSize={'xl'} fontWeight={'bold'}>
        請求と支払い
      </Text>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        w={'full'}
        alignItems={'flex-start'}
        bgColor={'white'}
        borderWidth={'1px'}
        borderColor={'gray.200'}
        spacing={0}
        divider={<StackDivider borderColor={'gray.200'} />}
      >
        <VStack w={'full'} alignItems={'flex-start'} p={'16px'}>
          <Text fontWeight={'bold'} color={'gray.800'}>
            お支払い登録・ご利用状況
          </Text>
          <OkBadge
            text={
              providerAccount.paymentStatus === 'registered'
                ? '登録済み'
                : pd.remainFreeDays > 0
                ? `無料トライアル中(30日間)`
                : '未登録'
            }
          />

          <Text fontSize={'sm'} color={'gray.800'}>
            {providerAccount.paymentStatus === 'registered' ? (
              `お支払い登録は完了しています(${
                providerAccount.paymentMethod === 'card'
                  ? 'クレジットカード払い'
                  : providerAccount.paymentMethod === 'invoice'
                  ? '請求書払い'
                  : '支払い方法が不明です'
              })`
            ) : pd.remainFreeDays > 0 ? (
              <>
                無料トライアル終了まであと{pd.remainFreeDays}日
                <br />
                無料トライアル終了後も引き続きポチコの機能を利用するには、お支払い登録より月額課金を開始してください
              </>
            ) : (
              'お支払い登録が未完了です'
            )}
          </Text>
          <HStack>
            <InvoiceConfigurationChangeButton
              providerAccount={providerAccount}
            />
            {providerAccount.paymentStatus === 'unregistered' && (
              <Button
                as={Link}
                to={Path.paymentRegistration}
                size={'sm'}
                variant={'white-blue'}
              >
                支払い登録する
              </Button>
            )}
          </HStack>
        </VStack>

        {providerAccount.paymentStatus === 'registered' && (
          <VStack w={'full'} alignItems={'flex-start'} p={'16px'}>
            <Text fontWeight={'bold'} color={'gray.800'}>
              請求履歴
            </Text>
            <Text fontSize={'sm'} color={'gray.800'}>
              過去のお支払いについては外部サイト・決済ポータルから確認できます。
              <br />
              決済ポータルでクレジットカードを登録してもサービスのご利用は開始されませんのでご注意ください。
            </Text>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={portalQuery.data ?? '#'}
            >
              <Button
                colorScheme="gray"
                size={'sm'}
                isLoading={portalQuery.isLoading}
              >
                <HStack w={'full'} justifyContent={'space-between'}>
                  <Text>決済ポータルへ移動</Text>
                  <FaExternalLinkAlt />
                </HStack>
              </Button>
            </Link>
          </VStack>
        )}
      </Stack>
    </VStack>
  );
};

const InvoiceConfigurationChangeButton: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  if (providerAccount.paymentStatus === 'unregistered') {
    return null;
  }
  return (
    <Button
      as={Link}
      to={Path.changeInvoiceConfiguration}
      size={'sm'}
      variant={'white-blue'}
    >
      請求先情報を変更する
    </Button>
  );
};

const FeatureConfiguration = ({
  providerAccount,
}: {
  providerAccount: ProviderAccount;
}) => {
  return (
    <VStack w={'full'} alignItems={'flex-start'}>
      <Text fontSize={'xl'} fontWeight={'bold'}>
        各種機能
      </Text>
      <VStack
        w={'full'}
        alignItems={'flex-start'}
        bgColor={'white'}
        borderWidth={'1px'}
        borderColor={'gray.200'}
        divider={<StackDivider borderColor={'gray.200'} />}
        spacing={0}
      >
        <RichMenuConfiguration providerAccount={providerAccount} />
        <ThemeConfiguration />
        <BookingMenuConfiguration providerAccount={providerAccount} />
        <ReminderConfiguration />
        <BookingConfiguration />
        <GoogleCalendarConfiguration providerAccount={providerAccount} />
      </VStack>
    </VStack>
  );
};

const RichMenuConfiguration = ({
  providerAccount,
}: {
  providerAccount: ProviderAccount;
}) => {
  return (
    <VStack w={'full'} alignItems={'flex-start'} bgColor={'white'}>
      <ConfigurationMenu
        title="ポチコ用リッチメニュー"
        imagePath={'/assets/configuration_richmenu.png'}
      >
        <VStack w={'full'} alignItems={'flex-start'}>
          <Text>
            『ON』にするとポチコで提供しているリッチメニューが表示されます。
            <br />
            『OFF』にすると、オリジナルのリッチメニューと連携しない限りユーザーは予約ができませんのでご注意ください。
            <br />
            <br />
            ポチコ用のリッチメニューを使用せず、オリジナルのリッチメニューと連携するには、
            <Link
              to={'https://help.pochico.app/2a68b2c864dc4500b4421c72972afd5f'}
              target={'_blank'}
            >
              ヘルプ
            </Link>
            をご覧ください。
          </Text>
          <RichMenuSwitcher providerAccount={providerAccount} />
        </VStack>
      </ConfigurationMenu>
    </VStack>
  );
};

const BookingMenuConfiguration = ({
  providerAccount,
}: {
  providerAccount: ProviderAccount;
}) => {
  return (
    <VStack w={'full'} alignItems={'flex-start'} bgColor={'white'}>
      <ConfigurationMenu
        title="予約メニュー"
        imagePath={'/assets/configuration_bookingMenu.png'}
      >
        <VStack w={'full'} alignItems={'flex-start'}>
          <Text>
            予約メニューとはユーザーが予約内容を選択することができる機能です。
            <br />
            メニューが１つしかない場合は機能をオフにしてください。
          </Text>
          <HStack whiteSpace={'nowrap'} spacing={'8px'} alignItems={'center'}>
            <Text>現在の設定：</Text>
            <NeedBookingMenuSwitcher providerAccount={providerAccount} />
          </HStack>
        </VStack>
      </ConfigurationMenu>
    </VStack>
  );
};

const RichMenuSwitcher = ({
  providerAccount,
}: {
  providerAccount: ProviderAccount;
}) => {
  const toast = useToast();
  const {
    isEnabledPochicoRichMenu,
    loading,
    setDefaultRichMenu,
    deleteDefaultRichMenu,
  } = useRichMenu();

  return (
    <HStack alignItems={'center'}>
      {loading ? (
        <Spinner />
      ) : (
        <HStack spacing={'8px'} alignItems={'center'}>
          <Text>現在の設定：</Text>
          <Text>{isEnabledPochicoRichMenu ? 'ON' : 'OFF'}</Text>
          <Switch
            disabled={loading}
            isChecked={isEnabledPochicoRichMenu}
            colorScheme={'blue'}
            onChange={(event) => {
              const isCurrentChecked = event.currentTarget.checked;
              if (isEnabledPochicoRichMenu === isCurrentChecked) {
                return;
              }
              const p = (() => {
                if (isCurrentChecked) {
                  // trueになった = リッチメニューを出す
                  return setDefaultRichMenu();
                } else {
                  // falseになった = リッチメニューを消す
                  return deleteDefaultRichMenu();
                }
              })();
              toast.closeAll();
              p.then(() => {
                toast({
                  status: 'success',
                  title: `ポチコのリッチメニューを${
                    isCurrentChecked ? 'ON' : 'OFF'
                  }にしました`,
                });
              }).catch((err) => {
                console.error(
                  `リッチメニューの設定に失敗しました. providerAccountId: ${providerAccount?.id} ${err}`,
                  err
                );
                {
                  toast({
                    status: 'error',
                    title: `ポチコのリッチメニューの設定変更に失敗しました(${err})`,
                  });
                }
              });
            }}
            name="checkedA"
          />
        </HStack>
      )}
    </HStack>
  );
};

const ReminderConfiguration = () => {
  return (
    <ConfigurationMenu
      title="リマインダー"
      imagePath={'/assets/configuration_reminder.png'}
    >
      <VStack w={'full'} alignItems={'flex-start'}>
        <Text>
          予約をしたお客様向けに予約日の前日17時ごろにメッセージと地図を送ることができます。
        </Text>
        <NavigationButton variant={'white-blue'} to={Path.reminder}>
          設定する
        </NavigationButton>
      </VStack>
    </ConfigurationMenu>
  );
};

// 予約関連の設定をまとめる(現在は上限回数のみなので具体的な名前にしておく)
const BookingConfiguration = () => {
  return (
    <ConfigurationMenu
      title="同時に予約できる数を制限する"
      // imagePath={'/assets/configuration_reminder.png'}
    >
      <VStack w={'full'} alignItems={'flex-start'}>
        <Text>
          1人のお客様にたくさん予約枠を押さえられてしまうことを防止する機能です。
        </Text>
        <NavigationButton variant={'white-blue'} to={Path.bookingConfiguration}>
          設定する
        </NavigationButton>
      </VStack>
    </ConfigurationMenu>
  );
};

const GoogleCalendarConfiguration: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  return (
    <ConfigurationMenu title="Googleカレンダー連携">
      <VStack w={'full'} alignItems={'flex-start'}>
        <Text>
          Googleカレンダー連携を行うと、Googleカレンダーの機能で事前にリマインダーなどを受け取ったり、他のスタッフにカレンダーを共有したりもできます。
        </Text>
        {providerAccount.calendar && (
          <HStack>
            <OkBadge
              text={
                providerAccount.calendar.email
                  ? `連携済み ${providerAccount.calendar.email}`
                  : '連携済み'
              }
            />
          </HStack>
        )}
        <GoogleCalendarConfigurationButton providerAccount={providerAccount} />
        {/* <NavigationButton
          target={'_blank'}
          rel="noopener noreferrer"
          to={`${GLOBAL_CONFIG.MY_URL.SERVER.ORIGIN}/auth/google/gcal?account=${providerAccount.id}`}
          variant={'white-blue'}
          // isDisabled={!!providerAccount.calendar}
        >
          {providerAccount.calendar ? '再連携する' : '連携する'}
        </NavigationButton> */}
      </VStack>
    </ConfigurationMenu>
  );
};

const ThemeConfiguration = () => {
  return (
    <ConfigurationMenu
      title="テーマ設定"
      imagePath={'/assets/configuration_theme.png'}
    >
      <VStack w={'full'} alignItems={'flex-start'}>
        <Text>LINEトークで送信するメッセージの色などを変更できます。</Text>
        <NavigationButton variant={'white-blue'} to={Path.theme}>
          設定する
        </NavigationButton>
      </VStack>
    </ConfigurationMenu>
  );
};

const AccountConfiguration = () => {
  return (
    <VStack w={'100%'} alignItems={'flex-start'}>
      <Text fontWeight={'bold'}>アカウント設定</Text>
      <VStack
        w={'full'}
        alignItems={'flex-start'}
        bgColor={'white'}
        borderWidth={'1px'}
        borderColor={'gray.200'}
        divider={<StackDivider borderColor={'gray.200'} />}
        spacing={0}
      >
        <PasswordConfiguration />
        <LineAttachConfigurationCard />
        <LiffIdRegistration />
        <ChurnSection />
      </VStack>
    </VStack>
  );
};

const PasswordConfiguration = () => {
  return (
    <ConfigurationMenu title="パスワード変更">
      <VStack w={'full'} alignItems={'flex-start'}>
        <Text>ログインに使用するパスワードを変更します。</Text>
        <NavigationButton variant={'white-blue'} to={'/change-password'}>
          設定画面
        </NavigationButton>
      </VStack>
    </ConfigurationMenu>
  );
};

const LiffIdRegistration = () => {
  const { providerAccount } = useAuthState();
  if (!providerAccount || !providerAccount.liff?.bookingPage) {
    return <></>;
  }
  return (
    <ConfigurationMenu title={'LIFF ID登録'}>
      <VStack w={'full'} alignItems={'flex-start'}>
        <Text>ポチコLIFF用のLIFFアプリIDを登録します</Text>
        <Link to={Path.lineLiffIdRegistration}>
          <Button variant={'white-blue'}>
            {providerAccount?.liff?.bookingPage
              ? `変更する(登録済み: ${providerAccount.liff.bookingPage})`
              : '登録する'}
          </Button>
        </Link>
      </VStack>
    </ConfigurationMenu>
  );
};

const LineAttachConfigurationCard = () => {
  const { providerAccount } = useAuthState();
  if (!providerAccount) {
    return <></>;
  }
  if (providerAccount.lineChannelType === 'messagingApi') {
    return (
      <ConfigurationMenu title={'LINE連携方式'}>
        <VStack w={'full'} alignItems={'flex-start'}>
          <HStack>
            <Text>Messaging API連携</Text>
            <OkBadge text={'連携済み'} />
          </HStack>
          <Link to={Path.lineOaLinkMessagingApi}>
            <Button variant={'white-blue'}>連携情報変更</Button>
          </Link>
        </VStack>
      </ConfigurationMenu>
    );
  } else {
    return (
      <ConfigurationMenu title={'LINE連携方式'}>
        <VStack w={'full'} alignItems={'flex-start'}>
          <HStack>
            <Text>LINEマーケットプレイス</Text>
            <OkBadge text={'連携済み'} />
          </HStack>
        </VStack>
      </ConfigurationMenu>
    );
  }
};

const ChurnSection = () => {
  return (
    <ConfigurationMenu title="解約する">
      <VStack w={'full'} alignItems={'flex-start'}>
        <Text>かんたん予約ポチコを解約し、LINEとの連携を解除します。</Text>
        <NavigationButton variant={'red-fill'} to={Path.churn}>
          解約画面
        </NavigationButton>
      </VStack>
    </ConfigurationMenu>
  );
};

const OkBadge: React.FC<{ text: string }> = ({ text }) => {
  return (
    <HStack color={'green.500'} spacing={'4px'}>
      <Icon as={MdCheckCircle} boxSize={'24px'} />
      <Text fontWeight={'bold'}>{text}</Text>
    </HStack>
  );
};

const Version = () => {
  return (
    <HStack
      position={'absolute'}
      bottom={'10px'}
      right={'10px'}
      fontSize={'10px'}
      w={'full'}
      justifyContent={'flex-end'}
    >
      <Text>バージョン:{GLOBAL_CONFIG.GIT_HASH}</Text>
    </HStack>
  );
};
